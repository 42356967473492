import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import {Container, Col, Row} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'

// import styled from 'styled-components'
const colClassName = 'col-12 col-md-6'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="Aligner splash-banner">
      <div className="cover-text"> Lean Digital Consulting </div>
    </div>
    <div>
      <div className="full-width-div s1">
        <Container>
          <Row>
            <Col className="col-12 col-lg-6">
              <h1> What We Do </h1>
              <ul>
                <li> Automation </li>
                <li> Web Applications </li>
                <li> Data Engineering & Machine Learning </li>
                <li> Websties Wordpress Alternatives </li>
              </ul>
            </Col>
            <Col className="col-12 col-lg-6">
              <h1> Our Approach </h1>
              <p>
                We understand startups, SMEs and larger organisations often have
                different needs.
                <br />
                We believe in agile project management and shipping Minimal
                Viable Product early on and iterative development.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="full-width-div s1">
        <Container>
          <h1> Our Process </h1>
          <Row>
            <Col className="col-12 col-lg-3 ">
              <h2> 1. Initial Free Consult </h2>
              <p>
                We identify the pain points and business incentives of your
                project.{' '}
              </p>
            </Col>
            <Col className="col-12 col-lg-3 ">
              <h2> 2. Initial Plan </h2>
              <p>
                Depending on the likely complexity, we use a mix of meetings,
                emails and scoping day to identify an initial plan
              </p>
            </Col>
            <Col className="col-12 col-lg-3 ">
              <h2> 3. Agile implementation & Rollout </h2>
              <p>
                We implement the product with your feedbacks and roll it out
                iteratively{' '}
              </p>
            </Col>

            <Col className="col-12 col-lg-3 ">
              <h2> 4. Post Launch </h2>
              <p>
                Post-launch, we provide technical support and maintenace, with
                some hours provided as part of the build{' '}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="full-width-div s2">
        <Container>
          <Row>
            <Col className="col-12">
              {' '}
              <h1> Team </h1>{' '}
            </Col>
            <Col className={colClassName}>
              <div
                style={{
                  maxWidth: `200px`,
                  maxHeight: `200px`,
                  marginBottom: `1.45rem`,
                }}>
                <Image />
              </div>
              <h2> Felix Kam </h2>
              <ul>
                <li> Ex Microsoft US, PwC & Data61(CSIRO) </li>
                <li>
                  Founder of dotd, a venture funded startup that had secured
                  Jobs For NSW's MVP Grant.
                </li>
                <li>
                  {' '}
                  <a href="https://www.linkedin.com/in/felixkam42/">
                    {' '}
                    LinkedIn{' '}
                  </a>{' '}
                </li>
              </ul>
            </Col>
            <Col>
              <h2> Sub-Contractors </h2>
              <p>
                {' '}
                We work with
                <ul>
                  <li>Experienced Designers</li>

                  <li>Digital Marketers</li>

                  <li>Subcontracted developers and data scientists.</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <div className="full-width-div s3">
      <Container>
        <Row>
          <Col className={'col-12 col-md-8'}>
            <h1> Technologies </h1>
            We use opne source and well maintained technologies, and find the
            best tool for the job instead of seeing everything as a nail just
            because we have hammer. <br /> <br />
            We generally prefer the following stack:
            <ul>
              <li> React </li>
              <li> Node.js </li>
              <li> Bootstrap </li>
              <li> SQL </li>
              <li> Gatsby </li>
              <li> GraphQL </li>
              <li> AWS </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="full-width-div s4">
      <Container>
        <Row>
          <Col className={'col-12 col-md-8'}>
            <h1> Get Started </h1>
            <a>
              I am not taking on new clients for active development right now. Please feel free to
              email us at felixkam42@gmail.com if you would like a referral.
            </a>
            .
          </Col>
        </Row>
      </Container>
    </div>
    {/*
    <Link to="/page-2/">Go to page 2</Link>
    */}
  </Layout>
)

export default IndexPage
